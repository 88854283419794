import Svg from "./Svg";

export const getHeight = (width: number, ratio: number) => {
  return Math.round(width * ratio);
};

export type Props = {
  width: number;
  className?: any;
  height?: number;
  style?: any;
  color?: string;
};

export const Play = ({
  className,
  height,
  style,
  width,
}: Props): JSX.Element => (
  <Svg
    className={className}
    height={height || getHeight(width, 31 / 15)}
    style={style}
    viewBox="0 0 15 31"
    width={width}
  >
    <path
      d="M0.5,31c0.1,0,0.3-0.1,0.4-0.2l13.4-15c0.2-0.2,0.2-0.5,0-0.7L0.9,0.2C0.7,0,0.4-0.1,0.2,0.1
              C0,0.3-0.1,0.6,0.1,0.8l13.1,14.7L0.1,30.2c-0.2,0.2-0.2,0.5,0,0.7C0.3,31,0.4,31,0.5,31z"
    />
  </Svg>
);

export const Pause = ({
  className,
  height,
  style,
  width,
}: Props): JSX.Element => (
  <Svg
    className={className}
    height={height || getHeight(width, 84 / 41)}
    style={style}
    viewBox="0 0 41 84"
    width={width}
  >
    <rect width="6" height="84" />
    <rect x="35" width="6" height="84" />
  </Svg>
);

export const SoundOff = ({
  className,
  height,
  style,
  width,
}: Props): JSX.Element => (
  <Svg
    className={className}
    height={height || getHeight(width, 21 / 22)}
    style={style}
    viewBox="0 0 22 21"
    width={width}
  >
    <path d="M12.5 21c-.1 0-.3-.1-.4-.1l-3.5-3.5c-.2-.2-.2-.5 0-.7s.5-.2.7 0l2.6 2.6V12c0-.3.2-.5.5-.5s.6.2.6.5v8.5c0 .2-.1.4-.3.5h-.2zM4.5 14H2c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h3.3L12.2.1c.1-.1.3-.1.5-.1.2.1.3.3.3.5V6c0 .3-.2.5-.5.5S12 6.3 12 6V1.7L5.9 7.9c-.1 0-.3.1-.4.1H2c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1h2.5c.3 0 .5.2.5.5s-.2.5-.5.5z" />
    <path d="M13 13.5h-.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h.5c1.1 0 2-.9 2-2 0-.3.2-.5.5-.5s.5.2.5.5c0 1.7-1.3 3-3 3zM1.5 21c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l20-20c.2-.2.5-.2.7 0s.2.5 0 .7l-20 20c0 .1-.2.1-.3.1z" />
  </Svg>
);

export const SoundOn = ({
  className,
  height,
  style,
  width,
}: Props): JSX.Element => (
  <Svg
    className={className}
    height={height || getHeight(width, 21 / 24)}
    style={style}
    viewBox="0 0 24 21"
    width={width}
  >
    <path d="M12.5 21c-.1 0-.3-.1-.4-.1L5.3 14H2c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h3.3L12.2.1c.1-.1.3-.1.5-.1.2.1.3.3.3.5v20c0 .2-.1.4-.3.5h-.2zM2 8c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1h3.5c.1 0 .3.1.4.1l6.1 6.1V1.7L5.9 7.9c-.1 0-.3.1-.4.1H2zM20 19c-.1 0-.3-.1-.4-.2-.2-.2-.2-.5 0-.7 2.1-1.9 3.3-4.6 3.3-7.6s-1.2-5.8-3.3-7.6c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0 2.3 2 3.7 5.1 3.7 8.4s-1.3 6.3-3.7 8.4H20z" />
    <path d="M17.5 17c-.1 0-.3-.1-.4-.2-.2-.2-.1-.5.1-.7 1.7-1.4 2.8-3.6 2.8-5.6 0-2-1.1-4.2-2.8-5.6-.2-.2-.2-.5-.1-.7.2-.2.5-.2.7-.1 1.9 1.6 3.2 4.1 3.2 6.4 0 2.3-1.2 4.8-3.2 6.4-.1.1-.2.1-.3.1z" />
    <path d="M15.5 14.5c-.2 0-.3-.1-.4-.2-.2-.2-.1-.5.1-.7C16 13 17 12 17 10.5S16 8 15.2 7.4c-.2-.2-.3-.5-.1-.7.2-.2.5-.3.7-.1 1.4 1 2.2 2.4 2.2 3.9s-.8 2.9-2.2 3.9c-.1.1-.2.1-.3.1z" />
  </Svg>
);
