import type { FC } from "react";

export const remify = (number: number) => {
  return number / 10 + "rem";
};

export interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  height?: number;
  width?: number;
  style?: object;
  viewBox: string;
  fill?: string;
}

const Svg: FC<Props> = ({
  children,
  className,
  height,
  width,
  fill,
  style,
  viewBox,
  ...ariaProps
}) => {
  return (
    <svg
      aria-hidden={true}
      viewBox={viewBox}
      style={style}
      width={width ? remify(width) : "100%"}
      height={height ? remify(height) : "100%"}
      fill={fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...ariaProps}
    >
      {children}
    </svg>
  );
};

export default Svg;
