import "./App.scss";
import Video from "./VideoEmbed/Video";
import { IntlProvider } from "react-intl";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <IntlProvider locale="en">
        <div className="site">
          <Video
            id="d3ff5654c8ed54f6228588b6c0e15a72"
            provider="cloudflare"
            controls="full"
          />
        </div>
      </IntlProvider>

      <Analytics />
    </>
  );
}

export default App;
