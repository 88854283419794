import React, { useEffect, useState, useRef } from "react";
import VimeoPlayer from "@vimeo/player";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import { Play, Pause, SoundOff, SoundOn } from "./icons";
import messages from "./messages";
import styles from "./Video.module.scss";
import { Stream } from "@cloudflare/stream-react";

type Provider = "youtube" | "vimeo" | "cloudflare";
type Controls = "minimal" | "none" | "full";

type Props = {
  autoplay?: boolean;
  className?: string;
  controls?: Controls;
  hasAudio?: boolean;
  id: string;
  provider?: Provider;
  ratio?: number;
  idSuffix?: string;
};

export function getPaddingFromRatio(ratioAsNumber: number) {
  return ratioAsNumber ? Math.floor(ratioAsNumber * 10000) / 100 : 0;
}

const Video = ({
  autoplay = false,
  className = "",
  controls = "minimal",
  hasAudio = true,
  id,
  idSuffix = "",
  provider = "vimeo",
  ratio = 9 / 16,
  ...props
}: Props): JSX.Element => {
  const vimeoPlayer: any = useRef(null);
  const cloudflarePlayer: any = useRef(null);
  const { formatMessage } = useIntl();
  const [isMuted, setIsMuted] = useState(!!autoplay);
  const [isPlaying, setIsPlaying] = useState(!!autoplay);
  const [isSuspended, setIsSuspended] = useState(false);
  const shouldAutoPlay = controls === "none" ? true : autoplay;
  const shouldBeMuted = shouldAutoPlay || !hasAudio;
  const videoNodeId = `nj-video_${id}${idSuffix && `_${idSuffix}`}`;

  // ------------------------------------------------------------------------------------------------------------------------------------
  // 2023-02-07
  // Might display "Error: Unknown player. Probably unloaded." error in development, problably caused by the two initial renders?
  // Error goes away if we remove the destroy() in the return function of our mount useEffect but best to keep it, right?
  // ------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const videoNode = document.getElementById(videoNodeId);

    videoNode &&
      videoNode.addEventListener("suspend", () => {
        setIsSuspended(true);
      });

    if (controls === "none" && provider === "youtube") {
      console.warn(
        `${provider} might not support ${controls} controls so well, proceed with caution`
      );
    }

    if (provider === "cloudflare") {
    }

    if (provider === "vimeo") {
      if (vimeoPlayer.current === null) {
        vimeoPlayer.current = new VimeoPlayer(videoNodeId, {
          autoplay: shouldAutoPlay,
          background: controls !== "full",
          byline: false,
          color: "ffffff",
          controls: controls === "full",
          dnt: true, // Do not track, should include cookies
          height: 218,
          loop: true,
          muted: shouldBeMuted,
          portrait: false,
          title: false,
          width: 500,
          url: `https://vimeo.com/${id}`,
        });
      }

      if (!shouldAutoPlay) {
        // 2023-01-27: It seems to autoplay regardless of what we put in the vimeoPlayer create object... so we have to stop it manually below if it shouldnt autoplay

        vimeoPlayer.current &&
          vimeoPlayer.current
            .pause()
            .then(() => {
              setIsPlaying(false);
              setIsSuspended(true);

              // 2023-02-06: it doesnt seem like muted works as well...
              vimeoPlayer.current.getMuted().then((muted: boolean) => {
                if (!shouldBeMuted && muted) {
                  vimeoPlayer.current.setMuted(false).then(() => {
                    setIsMuted(false);
                  });
                }
              });
            })
            .catch((error: string) => {
              console.log("error pausing:", error);
            });
      }
    }

    return () => {
      vimeoPlayer.current = null;
    };
  }, [id]);

  const playButton = async () => {
    if (provider === "vimeo") {
      const player = vimeoPlayer.current;
      if (player) {
        const paused = await player.getPaused();
        if (!paused) {
          if (autoplay && hasAudio) {
            // should probably check if sound is on here but since sound is always muted on autoplay we just use that?
            // if autoplay is enabled, the first click brings back the sound instead of pausing.
            toggleAudio();
          } else {
            togglePlay();
          }
        } else {
          togglePlay();
        }
      }
    } else if (provider === "cloudflare") {
      togglePlay();
    }
  };

  const togglePlay = async () => {
    if (provider === "vimeo") {
      const player = vimeoPlayer.current;
      if (player) {
        const paused = await player.getPaused();
        if (paused) {
          await player.play();
          setIsPlaying(true);
          setIsSuspended(false);
        } else {
          await player.pause();
          setIsPlaying(false);
          setIsSuspended(true);
        }
      }
    } else if (provider === "cloudflare") {
      const player = cloudflarePlayer.current;
      if (player) {
        if (isPlaying) {
          player.pause();
          setIsPlaying(false);
        } else {
          player.play();
          setIsPlaying(true);
        }
      }
    }
  };

  const toggleAudio = async () => {
    const player = vimeoPlayer.current;
    if (player) {
      const muted = await player.current.getMuted();
      if (muted) {
        await player.setMuted(false);
        setIsMuted(false);
      } else {
        await player.setMuted(true);
        setIsMuted(true);
      }
    }
  };

  const toogleFullscreen = async () => {
    const player = vimeoPlayer.current;
    if (player) {
      const fullscreen = await player.getFullscreen();
      if (fullscreen) {
        await player.exitFullscreen();
      } else {
        await player.requestFullscreen();
      }
    }
  };

  return (
    <div
      className={classnames(
        styles.wrapper,
        { [styles.playing]: isPlaying },
        className
      )}
      style={ratio ? { paddingBottom: getPaddingFromRatio(ratio) + "%" } : {}}
      {...props}
    >
      {/* Ideally we don't want any interaction with the video at all, but we need to make sure it is playing.
          in Low Power Mode for example, videos set to autoplay won't start playing and will be put in "suspend" mode.
          If they are in this mode, we should a transparent button over the video so clicking it will start it.
      */}
      {controls === "none" && isSuspended && (
        <button className={styles.noControlsBtn} onClick={togglePlay} />
      )}
      {provider === "vimeo" && ( // https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters
        <div id={videoNodeId} />
      )}

      {provider === "cloudflare" ? (
        <Stream
          src={id}
          controls={controls === "full"}
          autoplay={autoplay}
          muted={autoplay}
          preload="auto"
          streamRef={cloudflarePlayer}
        />
      ) : null}

      {provider === "youtube" && (
        // https://developers.google.com/youtube/player_parameters
        // Note: modestbranding and color parameters don't work together. :(
        <iframe
          title={id}
          // id={`nj-video_${id}${idSuffix && `_${idSuffix}`}`}
          width="560"
          height="315"
          src={`https://www.youtube-nocookie.com/embed/${id}?rel=0&modestbranding=1&enablejsapi=1&color=white&controls=${
            controls === "none" ? 0 : 1
          }&loop=1&modestbranding=1&showinfo=0&autoplay=${
            autoplay || controls === "none" ? "1" : "0"
          }&mute=${autoplay || controls === "none" || !hasAudio ? "1" : "0"}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}

      {(provider === "vimeo" || provider === "cloudflare") &&
        controls === "minimal" && (
          <>
            <button className={styles.playButton} onClick={playButton}>
              <div>
                <div className={styles.playButtonSvgWrapper}>
                  <Play width={17} />
                </div>
                <span className={styles.label}>
                  <FormattedMessage {...messages.play} />
                </span>
              </div>
            </button>

            <div className={styles.controlDrawer}>
              <div className={styles.pauseButtonWrapper}>
                <button
                  className={styles.pauseButton}
                  onClick={togglePlay}
                  aria-label={formatMessage(messages.pause)}
                >
                  <Pause width={9} />
                </button>
              </div>
              {hasAudio && (
                <button
                  className={styles.muteButton}
                  onClick={toggleAudio}
                  aria-pressed={!isMuted}
                  aria-label={
                    isMuted
                      ? formatMessage(messages.unmute)
                      : formatMessage(messages.mute)
                  }
                >
                  {isMuted ? <SoundOff width={20} /> : <SoundOn width={22} />}
                </button>
              )}
              <button
                className={styles.pauseButton}
                onClick={toogleFullscreen}
                aria-label={formatMessage(messages.pause)}
              >
                <Pause width={9} />
              </button>
            </div>
          </>
        )}
    </div>
  );
};

export default Video;
