import { defineMessages } from 'react-intl'

const messages = defineMessages({
  play: {
    defaultMessage: 'Play',
    description: 'Text for "Play" button in video player',
    id: 'UI.VideoEmbed.play',
  },
  pause: {
    defaultMessage: 'Pause',
    description: 'Text for "Pause" button in video player',
    id: 'UI.VideoEmbed.pause',
  },
  mute: {
    defaultMessage: 'Mute video audio',
    description: 'Text for "Mute" button in video player',
    id: 'UI.VideoEmbed.mute',
  },
  unmute: {
    defaultMessage: 'Unmute video audio',
    description: 'Text for "Unmute" button in video player',
    id: 'UI.VideoEmbed.unmute',
  },
  videoPlayer: {
    defaultMessage: 'Video player',
    description: 'Title for video player',
    id: 'UI.VideoEmbed.videoPlayer',
  },
})

export default messages
